<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-select
          v-if="!filterStatus"
          :placeholder="$t('COMMON.STATUS')"
          v-model="selectedStatus"
        >
          <el-option :value="0" :label="$t('COMMON.ALL_STATUSES')"> </el-option>
          <el-option
            v-for="(value, key) in statusesOptions"
            :key="key"
            :value="key"
            :label="value"
          >
          </el-option>
        </el-select>

        <organization-selector
          v-if="
            !filterOrganization &&
            !filterRecipient &&
            !filterSalesOrder &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />

        <base-input
          v-if="
            !filterSalesOrder &&
            $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
          "
          :placeholder="`${$t('COMMON.LOCATIONS')}`"
        >
          <locations-selector
            @locationsChanged="(locations) => (selectedLocations = locations)"
          />
        </base-input>

        <base-input class="dates" placeholder="Dates">
          <flat-picker
            :config="{
              allowInput: true,
              mode: 'range',
              locale: flatPickrLocale,
            }"
            class="form-control datepicker"
            v-model="dateRange"
            placeholder="Dates"
          >
          </flat-picker>
        </base-input>

        <div class="code">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.CODE')"
            clearable
          />
        </div>
      </div>
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="salesDeliveries"
        @sort-change="sortChange"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column
          :label="$t('COMMON.CODE')"
          prop="code"
          sortable="custom"
        />
        <el-table-column :label="$t('COMMON.STATUS')" prop="status">
          <template slot-scope="{ row }">
            <sales-delivery-status-badge :salesDelivery="row" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('COMMON.RECIPIENT')">
          <template v-slot="{ row }">
            <object-link :object="row.recipient" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.LOCATIONS')"
          v-if="
            !filterSalesOrder &&
            $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
          "
        >
          <template v-slot="{ row }">
            <locations :locations="row.allowedLocations" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.ORGANIZATION')"
          v-if="
            !filterOrganization &&
            !filterSalesOrder &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
        >
          <template v-slot="{ row }">
            <organization :organization="row.organization" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('SALES_DELIVERIES.EXPIRATION_TIME')"
          prop="expiration_time"
          sortable="custom"
        />
        <el-table-column
          :label="$t('COMMON.CREATED_AT')"
          prop="created_at"
          sortable="custom"
        >
          <template v-slot="{ row }">
            {{ $formatDate(row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_SALES_DELIVERIES)"
            >
              <a
                type="text"
                @click="viewSalesDelivery(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              :content="$t('COMMON.EDIT')"
              placement="top"
              v-if="
                $currentUserCan($permissions.PERM_EDIT_SALES_DELIVERIES) &&
                row.status == DELIVERY_STATUS_DRAFT
              "
            >
              <a
                type="text"
                @click="editSalesDelivery(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-edit"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="
                $currentUserCan($permissions.PERM_DELETE_SALES_DELIVERIES) &&
                row.status == DELIVERY_STATUS_DRAFT
              "
            >
              <a
                type="text"
                @click="deleteSalesDelivery(row.id)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-bdelivery"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  deliveriesStatusesOption,
  DELIVERY_STATUS_DRAFT,
} from "@/constants/deliveries";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SalesDeliveryStatusBadge from "./SalesDeliveryStatusBadge.vue";

export default {
  name: "sales-delivery-list-table",

  components: {
    BasePagination,
    flatPicker,
    OrganizationSelector,
    LocationsSelector,
    SalesDeliveryStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterSalesOrder: {
      type: String,
      default: null,
      description: "Order id",
    },
    filterRecipient: {
      type: Object,
      default: null,
      description: "Object with 'recipient_id' and 'recipient_type' ",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      salesDeliveries: [],
      loading: true,
      dateRange: null,
      selectedOrganization: null,
      selectedLocations: null,
      selectedStatus: null,
      selectedRecipient: null,
      statusesOptions: deliveriesStatusesOption,
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterRecipient: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedRecipient: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterSalesOrder: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
            ...(vDateRange.length == 2
              ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,recipient",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterSalesOrder) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              salesOrder: this.filterSalesOrder,
            },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterRecipient) {
          if (this.filterRecipient.type && this.filterRecipient.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                recipient_type: this.filterRecipient.type,
                recipient_id: this.filterRecipient.id,
              },
            };
          }
        }
        if (this.selectedRecipient) {
          if (this.selectedRecipient.type && this.selectedRecipient.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                recipient_type: this.selectedRecipient.type,
                recipient_id: this.selectedRecipient.id,
              },
            };
          }
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: { ...params.filter, status: this.filterStatus },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("salesDeliveries/list", params);
        this.salesDeliveries = this.$store.getters["salesDeliveries/list"];
        this.total = this.$store.getters["salesDeliveries/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSalesDelivery(id) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_DELIVERIES.DELETE_THIS_SALES_DELIVERY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesDeliveries/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewSalesDelivery(salesDelivery) {
      this.$router.push({
        name: "View SalesDelivery",
        params: { id: salesDelivery.id },
      });
    },

    async editSalesDelivery(row) {
      this.$router.push({
        name: "Edit SalesDelivery",
        params: { id: row.id },
      });
    },

    sortChange({ prop, salesDelivery }) {
      if (salesDelivery === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
