<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <div>
        <base-input
          v-if="salesOrderItemModel.salesOrderable.type.includes('products')"
          :label="`${$t(`COMMON.PRODUCT`)} (*)`"
        >
          <product-selector
            :filterOrganization="salesOrder.organization.id"
            :productObject="salesOrderItemModel.salesOrderable"
            :allowNone="false"
            :showAll="false"
            :filterWarehouse="
              salesOrder.sourceWarehouse ? salesOrder.sourceWarehouse.id : null
            "
            @productChanged="productChanged"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.salesOrderable" />
      </div>

      <base-input
        :label="`${$t('COMMON.CODE')} (*)`"
        :placeholder="$t('COMMON.CODE')"
        v-model="salesOrderItemModel.code"
        input-classes="form-control-alternative"
        v-if="!salesOrderItemModel.salesOrderable.type"
      >
      </base-input>
      <validation-error
        v-if="!salesOrderItemModel.salesOrderable.type"
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="salesOrderItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <div class="row px-3">
        <div class="col-4">
          <base-input
            :label="`${$t('COMMON.QUANTITY')} (*)`"
            :placeholder="$t('COMMON.QUANTITY')"
            v-model="salesOrderItemModel.quantity"
            type="number"
            step="1"
            input-classes="form-control-alternative"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.quantity" />
        </div>
        <div class="col-4">
          <base-input
            :label="$t('COMMON.UNIT_OF_MEASURE_UNIT')"
            :placeholder="$t('COMMON.UNIT_OF_MEASURE_UNIT')"
            input-classes="form-control-alternative"
          >
            <unit-of-measure-unit-selector
              :filterOrganization="salesOrder.organization.id"
              :filterUnitOfMeasure="unitOfMeasure?.id"
              :unit="salesOrderItemModel.unitOfMeasureUnit?.id"
              :allowNone="false"
              :showAll="false"
              @unitChanged="
                (unit) => {
                  unitOfMeasureUnitChanged(unit);
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.taxGroups" />
        </div>
        <div class="col-4">
          <base-input
            :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
            :placeholder="$t('COMMON.UNIT_PRICE')"
            v-model="salesOrderItemModel.unit_price"
            type="number"
            step="0.0001"
            input-classes="form-control-alternative"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.unit_price" />
        </div>
      </div>

      <template v-if="!salesOrder.has_no_taxes">
        <base-input
          :label="$t('COMMON.TAX_GROUPS')"
          :placeholder="$t('COMMON.TAX_GROUPS')"
          input-classes="form-control-alternative"
        >
          <tax-groups-selector
            :taxGroups="salesOrderItemModel.taxGroups"
            @taxGroupsChanged="
              (taxGroups) => {
                salesOrderItemModel.taxGroups = taxGroups;
              }
            "
          />
          <validation-error :errors="apiValidationErrors.taxGroups" />
        </base-input>
      </template>

      <base-input
        :label="$t('COMMON.DISCOUNT_PERCENT')"
        :placeholder="$t('COMMON.DISCOUNT_PERCENT')"
        v-model="salesOrderItemModel.discount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.discount" />
    </form>

    <template slot="footer">
      <button
        v-if="!salesOrderItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitSalesOrderItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_ORDERS.ADD_SALES_ORDER_ITEM") }}
      </button>

      <base-button
        v-if="salesOrderItemModel.id"
        native-type="submit"
        type="primary"
        class="new-sales-order-item--add"
        @click="submitSalesOrderItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_ORDERS.UPDATE_SALES_ORDER_ITEM") }}
      </base-button>

      <base-button
        v-if="salesOrderItemModel.id"
        type="danger"
        @click="deleteSalesOrderItem(salesOrderItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_ORDERS.DELETE_SALES_ORDER_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeSalesOrderItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductSelector from "@/components/ProductSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import UnitOfMeasureUnitSelector from "@/components/UnitOfMeasureUnitSelector.vue";
import TaxGroupsSelector from "@/components/TaxGroupsSelector.vue";
import defaultSalesOrderItem from "../defaultSalesOrderItem";

export default {
  name: "sales-order-view-details-sales-order-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    ProductSelector,
    TaxGroupsSelector,
    UnitOfMeasureUnitSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["salesOrder", "salesOrderItem", "showModal", "loading", "formErrors"],

  data() {
    return {
      salesOrderItemModel: cloneDeep(defaultSalesOrderItem),
      unitOfMeasure: null,
    };
  },

  computed: {},

  methods: {
    closeSalesOrderItemModal() {
      this.$emit("onCloseSalesOrderItemModal", true);
    },

    submitSalesOrderItemForm() {
      const salesOrderItemData = cloneDeep(this.salesOrderItemModel);
      salesOrderItemData.unit_price = parseFloat(salesOrderItemData.unit_price);
      salesOrderItemData.quantity = parseInt(salesOrderItemData.quantity, 10);
      salesOrderItemData.discount = parseFloat(salesOrderItemData.discount);
      if (!salesOrderItemData.salesOrderable.id) {
        salesOrderItemData.salesOrderable = null;
      }

      this.$emit("onSubmitSalesOrderItemForm", salesOrderItemData);
    },

    deleteSalesOrderItem(salesOrderItemId) {
      this.$emit("onDeleteSalesOrderItem", salesOrderItemId);
    },

    async unitOfMeasureUnitChanged(unit) {
      const productPricing = await this.$getProductUnitOfMeasureUnitPrice(
        this.salesOrderItemModel.salesOrderable,
        unit.id
      );
      this.salesOrderItemModel.unitOfMeasureUnit = unit;
      this.salesOrderItemModel.unit_price = productPricing.selling_price;
    },

    productChanged(productId, product) {
      this.salesOrderItemModel.salesOrderable = product;
      this.salesOrderItemModel.excerpt = product.denomination;

      if (product.warehouseProduct) {
        this.salesOrderItemModel.unit_price =
          product.warehouseProduct.selling_price;
        this.salesOrderItemModel.code =
          product.warehouseProduct.sku || product.sku || product.code;
      } else {
        this.salesOrderItemModel.unit_price = product.selling_price;
        this.salesOrderItemModel.code = product.code;
      }

      if (product.unitOfMeasure) {
        this.salesOrderItemModel.unitOfMeasureUnit =
          product.unitOfMeasure.referenceUnit;
      }
      if (!this.salesOrder.has_no_taxes) {
        this.salesOrderItemModel.taxGroups = product.taxGroups;
      } else {
        this.salesOrderItemModel.taxGroups = [];
      }
      this.unitOfMeasure = product.unitOfMeasure;
    },
  },

  mounted() {},

  watch: {
    salesOrderItem(salesOrderItem) {
      if (salesOrderItem) {
        this.salesOrderItemModel = cloneDeep(salesOrderItem);
      }
      this.resetApiValidation();
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
