<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openSalesOrderItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="salesOrder.status === ORDER_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("SALES_ORDERS.ADD_SALES_ORDER_ITEM") }}
        </base-button>
      </div>
    </div>

    <sales-order-view-details-sales-order-item-table
      :salesOrder="salesOrder"
      @onOpenSalesOrderItemModal="openSalesOrderItemModal"
    />

    <div class="row mb-5" v-if="salesOrder.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesOrder.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="salesOrder.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesOrder.pricing.discounts.total * -1) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in salesOrder.pricing.discounts.details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(discount.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0" v-if="salesOrder.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesOrder.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in salesOrder.pricing.taxes.taxes"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(tax.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesOrder.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <sales-order-view-details-sales-order-item-form
      :salesOrder="salesOrder"
      :salesOrderItem="salesOrderItem"
      :showModal="showModal"
      :formErrors="itemsFormError"
      @onCloseSalesOrderItemModal="closeSalesOrderItemModal"
      @onSubmitSalesOrderItemForm="handleSalesOrderItemFormSubmit"
      @onDeleteSalesOrderItem="deleteSalesOrderItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_CANCELED, ORDER_STATUS_DRAFT } from "@/constants/orders";
import SalesOrderViewDetailsSalesOrderItemForm from "./SalesOrderViewDetailsSalesOrderItemForm.vue";
import SalesOrderViewDetailsSalesOrderItemTable from "./SalesOrderViewDetailsSalesOrderItemTable.vue";
import defaultSalesOrderItem from "../defaultSalesOrderItem";

export default {
  name: "sales-order-view-details",

  components: {
    SalesOrderViewDetailsSalesOrderItemForm,
    SalesOrderViewDetailsSalesOrderItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: {
    showHeader: {
      default: true,
    },
    salesOrder: {
      default: {},
    },
  },

  data() {
    return {
      salesOrderItem: cloneDeep(defaultSalesOrderItem),
      loading: false,
      showModal: false,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      itemsFormError: null,
    };
  },

  computed: {},

  methods: {
    openSalesOrderItemModal(salesOrderItem = null) {
      if (!salesOrderItem) {
        this.salesOrderItem = cloneDeep(defaultSalesOrderItem);
      } else {
        this.salesOrderItem = cloneDeep(salesOrderItem);
      }
      this.showModal = true;
    },

    closeSalesOrderItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleSalesOrderItemFormSubmit(salesOrderItemData) {
      if (!salesOrderItemData.id) {
        this.addSalesOrderItem(salesOrderItemData);
      } else {
        this.editSalesOrderItem(salesOrderItemData);
      }
    },

    async addSalesOrderItem(salesOrderItemData) {
      this.loading = true;
      try {
        salesOrderItemData.salesOrder.id = this.salesOrder.id;

        await this.$store.dispatch("salesOrderItems/add", salesOrderItemData);
        this.$emit("salesOrderItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async editSalesOrderItem(salesOrderItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "salesOrderItems/update",
          salesOrderItemData
        );
        this.$emit("salesOrderItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async deleteSalesOrderItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("salesOrderItems/destroy", id);
        this.$emit("salesOrderItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
